import jQuery from 'jquery'

import whatInput from 'what-input';

window.jQuery = jQuery
window.$ = jQuery

import Foundation from 'foundation-sites';

if (Foundation) {
    // if `Foundation` is left as an unused variable
    // webpack will exclude it from the build output;
    // therefore, any expression that uses it will work.
}

$(document).foundation();